// CORE
// Este é um arquivo core do boilerplate e modificações neste arquivo serão bloqueadas via pré-hook de commit.
// Fazemos este bloqueio para manter a consistência do boilerplate e diminuir as chances de conflitos nos updates.
// Caso possível, procure uma alternativa ao uso deste arquivo.
// Se a alteração for realmente necessária ou for um update do boilerplate,
// realize o commit usando o comando 'git commit --no-verify'.

import {neutralColor} from '../colors';
import {inter} from '../fonts';

export const MuiDataGrid = {
  styleOverrides: {
    root: {
      borderRadius: 0,
      border: 0,
      fontFamily: inter.style.fontFamily,
    },
    columnHeaders: {
      backgroundColor: neutralColor[100],
      borderRadius: 0,
    },
    footerContainer: {
      border: 0,
    },
  },
};
