// CORE
// Este é um arquivo core do boilerplate e modificações neste arquivo serão bloqueadas via pré-hook de commit.
// Fazemos este bloqueio para manter a consistência do boilerplate e diminuir as chances de conflitos nos updates.
// Caso possível, procure uma alternativa ao uso deste arquivo.
// Se a alteração for realmente necessária ou for um update do boilerplate,
// realize o commit usando o comando 'git commit --no-verify'.

import {colorTokens} from '../colors';
import {inter} from '../fonts';

export const MuiButton = {
  styleOverrides: {
    root: {
      fontFamily: inter.style.fontFamily,
      textTransform: 'none',
      fontWeight: 600,
      fontSize: '1rem',
      boxShadow: 'none',
      '&:hover': {
        boxShadow: 'none',
      },
      '&:active': {
        boxShadow: 'none',
      },
    },
    outlined: {
      borderColor: colorTokens.button.secondary.border,
      backgroundColor: colorTokens.button.secondary.background,
      color: colorTokens.button.secondary.text,
    },
    contained: {
      backgroundColor: colorTokens.button.primary.background,
      color: colorTokens.button.primary.text,
      '&:hover': {
        backgroundColor: colorTokens.button.primary.background,
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundColor: colorTokens.button.primary.hoverModifier,
        },
      },
      '&:focus': {
        backgroundColor: colorTokens.button.primary.background,
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundColor: colorTokens.button.primary.focusedModifier,
        },
      },
      '&:active': {
        backgroundColor: colorTokens.button.primary.background,
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundColor: colorTokens.button.primary.pressedModifier,
        },
      },
      '&.Mui-disabled': {
        backgroundColor: colorTokens.button.primary.disabledBackground,
        color: colorTokens.button.primary.disabledText,
      },
    },
  },
};
