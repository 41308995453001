import {enqueueSnackbar} from 'notistack';

export function handleSubmitError(error, setFieldError) {
  if (error?.response?.data?.message == 'Validation error') {
    if (setFieldError) {
      Object.entries(error.response.data.extra.fields).map(
        ([field, errorMsg]) => {
          setFieldError(field, errorMsg);
        }
      );
    }
    enqueueSnackbar('Corrija os campos indicados!', {variant: 'error'});
  } else if (error?.response?.data?.message) {
    enqueueSnackbar(error.response.data.message, {variant: 'error'});
  } else {
    enqueueSnackbar('Aconteceu algo de errado. Tente novamente!', {
      variant: 'error',
    });
  }
}
