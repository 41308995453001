import {inter, manrope} from './fonts';

export const typography = {
  fontFamily: [
    inter.style.fontFamily,
    manrope.style.fontFamily,
    'Arial',
    'sans-serif',
  ],
  'display-large': {
    fontFamily: manrope.style.fontFamily,
    fontSize: '57px',
    fontWeight: 700,
    lineHeight: '64px',
  },
  'display-medium': {
    fontFamily: manrope.style.fontFamily,
    fontSize: '45px',
    fontWeight: 700,
    lineHeight: '52px',
  },
  'display-small': {
    fontFamily: manrope.style.fontFamily,
    fontSize: '36px',
    fontWeight: 700,
    lineHeight: '44px',
  },
  'headline-large': {
    fontFamily: manrope.style.fontFamily,
    fontSize: '32px',
    fontWeight: 700,
    lineHeight: '40px',
  },
  'headline-medium': {
    fontFamily: manrope.style.fontFamily,
    fontSize: '28px',
    fontWeight: 700,
    lineHeight: '36px',
  },
  'headline-small': {
    fontFamily: manrope.style.fontFamily,
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '32px',
  },
  'title-large': {
    fontFamily: manrope.style.fontFamily,
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '28px',
  },
  'title-medium': {
    fontFamily: manrope.style.fontFamily,
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '24px',
  },
  'title-small': {
    fontFamily: manrope.style.fontFamily,
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '20px',
  },
  'label-large': {
    fontFamily: inter.style.fontFamily,
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',
  },
  'label-medium': {
    fontFamily: inter.style.fontFamily,
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '20px',
  },
  'label-small': {
    fontFamily: inter.style.fontFamily,
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '16px',
  },
  'body-large': {
    fontFamily: inter.style.fontFamily,
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
  },
  'body-medium': {
    fontFamily: inter.style.fontFamily,
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
  },
  'body-small': {
    fontFamily: inter.style.fontFamily,
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
  },
  'body-tiny': {
    fontFamily: inter.style.fontFamily,
    fontSize: '10px',
    fontWeight: 400,
    lineHeight: '12px',
  },
};
