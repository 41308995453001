export const ROLE_ADMIN = 'admin';
export const ROLE_TESTE = 'teste';

export const PERMISSION_USERS_EDIT_OTHER_USERS = 'users.edit_other_users';
export const PERMISSION_USERS_EDIT_SELF = 'users.edit_self';

export const PERMISSION_GROUP_MAP = {
  'users.edit_other_users': ['admin'],
  'users.edit_self': ['admin', 'teste'],
};
