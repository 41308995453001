// CORE
// Este é um arquivo core do boilerplate e modificações neste arquivo serão bloqueadas via pré-hook de commit.
// Fazemos este bloqueio para manter a consistência do boilerplate e diminuir as chances de conflitos nos updates.
// Caso possível, procure uma alternativa ao uso deste arquivo.
// Se a alteração for realmente necessária ou for um update do boilerplate,
// realize o commit usando o comando 'git commit --no-verify'.

import {colorTokens} from '../colors';
import {inter} from '../fonts';

export const MuiTextField = {
  styleOverrides: {
    root: {
      fontFamily: inter.style.fontFamily,
      '& .MuiOutlinedInput-root': {
        backgroundColor: colorTokens.textInput.background,
        color: colorTokens.textInput.textFilled,
        '& fieldset': {
          borderColor: colorTokens.textInput.border,
        },
      },
      '& .MuiInputLabel-root': {
        color: colorTokens.textInput.textPlaceholder,
      },
    },
  },
};
