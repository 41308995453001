// CORE
// Este é um arquivo core do boilerplate e modificações neste arquivo serão bloqueadas via pré-hook de commit.
// Fazemos este bloqueio para manter a consistência do boilerplate e diminuir as chances de conflitos nos updates.
// Caso possível, procure uma alternativa ao uso deste arquivo.
// Se a alteração for realmente necessária ou for um update do boilerplate,
// realize o commit usando o comando 'git commit --no-verify'.

export const MuiTooltip = {
  defaultProps: {
    PopperProps: {
      container: () => document.getElementById('theme-root'),
    },
  },
};
