// CORE
// Este é um arquivo core do boilerplate e modificações neste arquivo serão bloqueadas via pré-hook de commit.
// Fazemos este bloqueio para manter a consistência do boilerplate e diminuir as chances de conflitos nos updates.
// Caso possível, procure uma alternativa ao uso deste arquivo.
// Se a alteração for realmente necessária ou for um update do boilerplate,
// realize o commit usando o comando 'git commit --no-verify'.

import {ptBR as corePtBR} from '@mui/material/locale';
import {createTheme} from '@mui/material/styles';
import {ptBR} from '@mui/x-data-grid';
import {Manrope} from 'next/font/google';
import {MuiButton} from './theme/components/Button';
import {MuiCard, MuiCardHeader} from './theme/components/Card';
import {MuiDataGrid} from './theme/components/DataGrid';
import {MuiDialogTitle} from './theme/components/DialogTitle';
import {MuiDrawer} from './theme/components/Drawer';
import {MuiFormControl} from './theme/components/FormControl';
import {MuiFormHelperText} from './theme/components/FormHelperText';
import {MuiInputBase} from './theme/components/InputBase';
import {MuiModal} from './theme/components/Modal';
import {MuiPopover} from './theme/components/Popover';
import {MuiSwitch} from './theme/components/Switch';
import {MuiTextField} from './theme/components/TextField';
import {MuiTooltip} from './theme/components/Tooltip';
import {MuiTypography} from './theme/components/Typography';
import {palette} from './theme/palette';
import {typography} from './theme/typography';

const manrope = Manrope({subsets: ['latin']});

export default function getTheme() {
  return {
    theme: createTheme(
      {
        palette,
        typography,
        components: {
          MuiTypography,
          MuiButton,
          MuiCard,
          MuiCardHeader,
          MuiFormControl,
          MuiInputBase,
          MuiTextField,
          MuiFormHelperText,
          MuiSwitch,
          MuiDrawer,
          MuiPopover,
          MuiModal,
          MuiTooltip,
          MuiDialogTitle,
          MuiDataGrid,
        },
      },
      ptBR,
      corePtBR
    ),
    fontClass: manrope,
  };
}
