// CORE
// Este é um arquivo core do boilerplate e modificações neste arquivo serão bloqueadas via pré-hook de commit.
// Fazemos este bloqueio para manter a consistência do boilerplate e diminuir as chances de conflitos nos updates.
// Caso possível, procure uma alternativa ao uso deste arquivo.
// Se a alteração for realmente necessária ou for um update do boilerplate,
// realize o commit usando o comando 'git commit --no-verify'.

export const MuiTypography = {
  defaultProps: {
    variantMapping: {
      'display-large': 'h1',
      'display-medium': 'h1',
      'display-small': 'h2',
      'headline-large': 'h1',
      'headline-medium': 'h2',
      'headline-small': 'h3',
      'title-large': 'h3',
      'title-medium': 'h4',
      'title-small': 'h5',
      'label-large': 'label',
      'label-medium': 'label',
      'label-small': 'label',
      'body-large': 'p',
      'body-medium': 'p',
      'body-small': 'p',
    },
    variant: 'body-medium',
  },
};
