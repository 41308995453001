import {ThemeProvider} from '@mui/material/styles';
import {SessionProvider} from 'next-auth/react';
import React from 'react';

import {
  HydrationBoundary,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import {ReactQueryDevtools} from '@tanstack/react-query-devtools';

import {AuthPageGate, CompletarPerfilPageGate} from '@/config/auth';
import getTheme from '@/config/theme';
import CloseIcon from '@mui/icons-material/Close';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import {SnackbarProvider, closeSnackbar} from 'notistack';

import {buildBatchedGetRequest} from '@/hooks/base_hooks';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/pt-br';

export default function App({Component, pageProps: {session, ...pageProps}}) {
  const [queryClient] = React.useState(
    () =>
      new QueryClient({
        queryCache: new QueryCache({}),
        defaultOptions: {
          queries: {
            queryFn: ({queryKey}) => {
              return buildBatchedGetRequest(queryKey, {})();
            },
            staleTime: 1000 * 60 * 3, // 30 seconds
          },
        },
      })
  );

  const {theme} = getTheme();
  return (
    <SessionProvider session={session}>
      <QueryClientProvider client={queryClient}>
        <HydrationBoundary state={pageProps.dehydratedState}>
          <ThemeProvider theme={theme}>
            <ReactQueryDevtools />
            <div id="theme-root">
              <CssBaseline />
              <SnackbarProvider
                autoHideDuration={3000}
                preventDuplicate={true}
                action={(snackbarId) => (
                  <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={() => closeSnackbar(snackbarId)}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                )}
              />
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale="pt-br"
              >
                <AuthPageGate
                  ispublic={Component?.auth?.isPublic}
                  permissions={Component?.auth?.permissions}
                >
                  <CompletarPerfilPageGate ispublic={Component?.auth?.isPublic}>
                    <Component {...pageProps} />
                  </CompletarPerfilPageGate>
                </AuthPageGate>
              </LocalizationProvider>
            </div>
          </ThemeProvider>
        </HydrationBoundary>
      </QueryClientProvider>
    </SessionProvider>
  );
}
