// CORE
// Este é um arquivo core do boilerplate e modificações neste arquivo serão bloqueadas via pré-hook de commit.
// Fazemos este bloqueio para manter a consistência do boilerplate e diminuir as chances de conflitos nos updates.
// Caso possível, procure uma alternativa ao uso deste arquivo.
// Se a alteração for realmente necessária ou for um update do boilerplate,
// realize o commit usando o comando 'git commit --no-verify'.

import {colorTokens} from '../colors';

export const MuiCard = {
  styleOverrides: {
    root: {
      borderRadius: '12px',
      boxShadow: 'none',
      backgroundColor: colorTokens.button.secondary.background,
      border: `1px solid ${colorTokens.border.default}`,
    },
  },
};

export const MuiCardHeader = {
  styleOverrides: {
    root: {},
    title: {
      fontSize: '20px',
      fontWeight: 700,
    },
  },
};
