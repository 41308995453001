// CORE
// Este é um arquivo core do boilerplate e modificações neste arquivo serão bloqueadas via pré-hook de commit.
// Fazemos este bloqueio para manter a consistência do boilerplate e diminuir as chances de conflitos nos updates.
// Caso possível, procure uma alternativa ao uso deste arquivo.
// Se a alteração for realmente necessária ou for um update do boilerplate,
// realize o commit usando o comando 'git commit --no-verify'.

import {handleSubmitError} from '@/components/notification/defaults';
import {
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import {
  create,
  keyResolver,
  windowedFiniteBatchScheduler,
} from '@yornaath/batshit';
import axios from 'axios';
import Cookies from 'cookies';
import {useEffect} from 'react';

const baseUrl = process.env.NEXT_PUBLIC_API_URL;

//queryFunction é OPCIONAL
export function useGetRequest(paramsArr, queryFunction) {
  if (!queryFunction) {
    queryFunction = buildBatchedGetRequest(paramsArr, {});
  }

  return useQuery({
    queryKey: paramsArr,
    queryFn: queryFunction,
  });
}

//queryParams é OBRIGATÓRIO e deve ser consistente com o queryParam das queries em array. Exemplo:
//  CommentArray => ["posts", 1, "comments"]
//  Comment => ["posts", 1, "comments", 1]

//queryTransformFunction é OPCIONAL. Ele modifica o valor visto pelo hook para os elementos. Não altera o que é armazenado no cache
// Assinatura esperada: (element) => tranformedElement

//sideEffectFunction é OPCIONAL. Ele é chamado com o valor retornado pela request, e deve retornar um array de sideEffects
// Assinatura esperada: (element) => [sideEffect1, sideEffect2, ...]
// O sideEffect é um objeto com os seguintes campos:
//  queryKey: Array com o queryKey da query que será atualizada
//  data: Novo valor da query

// IMPORTANTE: os side effects NÃO PODEM ALTERAR queries dentro da mesma queryKey fornecida para função, pois isso gera loop infinito
// CASO DE USO: A request do backend retorna dados de um objeto relacionado, e queremos atualizar o cache desse objeto relacionado. Ex:
// Comment: {id: 1, post: 1, content: "blablabla", author: {id: 1, name: "João"}}
// sideEffectFunction: (comment) => [{queryKey: ["users", comment.author.id], data: comment.author}]

export function useCustomGetRequest(
  queryKey,
  queryFunction,
  queryTransformFunction,
  sideEffectFunction
) {
  if (!queryFunction) {
    queryFunction = buildBatchedGetRequest(queryKey, {});
  }
  const queryClient = useQueryClient();
  return useQuery({
    queryKey,
    queryFn: queryFunction,
    onSuccess: (data) => {
      if (sideEffectFunction) {
        const sideEffects = sideEffectFunction(data);
        sideEffects.forEach((sideEffect) => {
          queryClient.setQueryData(sideEffect.queryKey, sideEffect.data);
        });
      }
    },
    select: (data) => {
      if (queryTransformFunction) {
        return queryTransformFunction(data);
      }
      return data;
    },
  });
}

//queryFunction é OPCIONAL
export function useGetArrayRequest(paramsArr, queryFunction) {
  return useCustomGetArrayRequest(paramsArr, {}, queryFunction, null, null);
}

//paramsArr é OBRIGATÓRIO e deve ser consistente com o paramsArr das queries individuais. Exemplo:
//  CommentArray => ["posts", 1, "comments"]
//  Comment => ["posts", 1, "comments", 1]

//queryParams é OPCIONAL. Caso fornecido (por exemplo, para paginação, filtrar, etc), ele será passado somente para a query do array, e não para as queries individuais
//queryFunction é OPCIONAL

//queryTransformFunction é OPCIONAL. Ele modifica o valor visto pelo hook para TODOS os elementos. Não altera o que é armazenado no cache
// Assinatura esperada: (arrayElement) => tranformedArrayElement

//sideEffectFunction é OPCIONAL. Ele é chamado com o valor de cada elemento do array, e deve retornar um array de sideEffects
// Assinatura esperada: (arrayElement) => [sideEffect1, sideEffect2, ...]
// O sideEffect é um objeto com os seguintes campos:
//  queryKey: Array com o queryKey da query que será atualizada
//  data: Novo valor da query

// IMPORTANTE: os side effects NÃO PODEM ALTERAR queries dentro da mesma queryKey fornecida para função, pois isso gera loop infinito
// CASO DE USO: A request do backend retorna dados de um objeto relacionado, e queremos atualizar o cache desse objeto relacionado. Ex:
// Comment: {id: 1, post: 1, content: "blablabla", author: {id: 1, name: "João"}}
// sideEffectFunction: (comment) => [{queryKey: ["users", comment.author.id], data: comment.author}]

export function useCustomGetArrayRequest(
  paramsArr,
  queryParams,
  queryFunction,
  queryTransformFunction,
  sideEffectFunction
) {
  if (!queryFunction) {
    queryFunction = buildGetRequest(paramsArr, queryParams);
  }
  let count, next, previous;

  const queryClient = useQueryClient();
  const {data, isLoading, isError, isSuccess, ...props} = useQuery({
    queryKey: [...paramsArr, queryParams],
    queryFn: queryFunction,
    staleTime: Infinity,
    select: (data) => {
      count = data.count;
      next = data.next;
      previous = data.previous;
      if (queryTransformFunction) {
        return data.results.map((elem) => queryTransformFunction(elem));
      }
      return data.results;
    },
  });

  useEffect(() => {
    if (isSuccess && data && sideEffectFunction) {
      data.forEach((arrayObj) => {
        sideEffectFunction(arrayObj).forEach((sideEffects) => {
          sideEffects.forEach((sideEffect) => {
            queryClient.setQueryData(sideEffect.queryKey, sideEffect.data);
          });
        });
      });
    }
  }, [data, isSuccess, sideEffectFunction, queryClient]);

  let queriesArr = [];
  if (isSuccess && data) {
    queriesArr = data.map((elem) => {
      return {
        queryKey: [...paramsArr, elem.id],
        queryFn: buildBatchedGetRequest([...paramsArr, elem.id], {}),
        initialData: elem,
        enabled: isSuccess,
      };
    });
  }

  const queries = useQueries({
    queries: queriesArr,
    combine: (results) => {
      return {
        data: results.map((result) => result.data),
        isPending: results.some((result) => result.isPending),
        isLoading: results.some((result) => result.isLoading),
        isError: results.some((result) => result.isError),
        isSuccess: results.every((result) => result.isSuccess),
      };
    },
  });

  useEffect(() => {
    if (queries.data && sideEffectFunction) {
      queries.data.forEach((data) => {
        if (data) {
          const sideEffects = sideEffectFunction(data);
          sideEffects.forEach((sideEffect) => {
            queryClient.setQueryData(sideEffect.queryKey, sideEffect.data);
          });
        }
      });
    }
  }, [queries.data, sideEffectFunction, queryClient]);

  if (!isSuccess) {
    return {data: null, isLoading, isError, isSuccess};
  }

  return {
    ...queries,
    count: count,
    next: next,
    previous: previous,
    ...props,
  };
}

//pathParamsArr é OBRIGATÓRIO e deve ser consistente com o pathParamsArr do GET
//Ex: GET =>  Comment => ["posts", 1, "comments", 1]
//    POST =>  Comment => ["posts", 1, "comments"]

//successCallback, errorCallback, startCallback são OPCIONAIS

// Se não forem passadas, o comportamento padrão será executado
// Se forem passadas, o comportamento padrão NÃO SERÁ EXECUTADO

// A assinatura das funcoes e o comportamento padrão é:

// 1. successCallback:
// (queryClient, oldData, newData) => {}
//  Comportamento padrão: Atualiza o react-query do elemento com o novo dado baseado no id retornado

// 2. errorCallback:
// (error, queryClient, oldData, newData) => {}
//  Comportamento padrão: Notistack de erro

// 3. startCallback:
// (queryClient, oldData, newData) => {}
// Comportamento padrão: Nada
// Observação: Se o startCallback é fornecido, o queryClient.cancelQueries é chamado automaticamente

export function usePostRequest(
  pathParamsArr,
  successCallback,
  errorCallback,
  startCallback,
  queryFunction
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (requestData) => {
      if (!queryFunction) {
        queryFunction = buildPostRequest(pathParamsArr, requestData);
      }
      return queryFunction;
    },
    onMutate: async (newData) => {
      await queryClient.cancelQueries({queryKey: pathParamsArr});
      const oldData = queryClient.getQueryData(pathParamsArr);
      if (startCallback) {
        startCallback(queryClient, oldData, newData);
      }
      return {oldData};
    },
    onSuccess: (newData, variables, context) => {
      if (successCallback) {
        successCallback(queryClient, context.oldData, newData);
      } else {
        queryClient.setQueryData([...pathParamsArr, newData.id], newData);
      }
    },
    onError: (error, variables, context) => {
      if (errorCallback) {
        errorCallback(error, queryClient, context.oldData, variables);
      } else {
        handleSubmitError(error, null);
      }
    },
  });
}

//successCallback, errorCallback, startCallback são OPCIONAIS

// Se não forem passadas, o comportamento padrão será executado
// Se forem passadas, o comportamento padrão NÃO SERÁ EXECUTADO

// A assinatura das funcoes e o comportamento padrão é:

// 1. successCallback:
// (queryClient, oldData, newData) => {}
//  Comportamento padrão: Atualiza o react-query do elemento com o novo dado baseado no pathParamArr fornecido
// 2. errorCallback:
// (error, queryClient, oldData, newData) => {}
//  Comportamento padrão: Notistack de erro

// 3. startCallback:
// (queryClient, oldData, newData) => {}
// Comportamento padrão: Nada
// Observação: Se o startCallback é fornecido, o queryClient.cancelQueries é chamado automaticamente

export function usePutRequest(
  pathParamsArr,
  successCallback,
  errorCallback,
  startCallback,
  queryFunction
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (requestData) => {
      if (!queryFunction) {
        queryFunction = buildPutRequest(
          [...pathParamsArr, requestData.id],
          requestData
        );
      }
      return queryFunction;
    },
    onMutate: async (newData) => {
      const queryKey = [...pathParamsArr, newData.id];
      const oldData = queryClient.getQueryData(queryKey);
      if (startCallback) {
        await queryClient.cancelQueries({queryKey: queryKey});
        startCallback(queryClient, oldData, newData);
      }
      return {oldData};
    },
    onSuccess: (newData, variables, context) => {
      const queryKey = [...pathParamsArr, newData.id];
      if (successCallback)
        successCallback(queryClient, context.oldData, newData);
      else {
        queryClient.setQueryData(queryKey, newData);
      }
    },
    onError: (error, variables, context) => {
      if (errorCallback)
        errorCallback(error, queryClient, context.oldData, variables);
      else {
        handleSubmitError(error, null);
      }
    },
  });
}

//successCallback, errorCallback, startCallback são OPCIONAIS

// Se não forem passadas, o comportamento padrão será executado
// Se forem passadas, o comportamento padrão NÃO SERÁ EXECUTADO

// A assinatura das funcoes e o comportamento padrão é:

// 1. successCallback:
// (queryClient, oldData, newData) => {}
//  Comportamento padrão: Atualiza o react-query do elemento com o novo dado baseado no pathParamArr fornecido
// 2. errorCallback:
// (error, queryClient, oldData, newData) => {}
//  Comportamento padrão: Notistack de erro

// 3. startCallback:
// (queryClient, oldData, newData) => {}
// Comportamento padrão: Nada
// Observação: Se o startCallback é fornecido, o queryClient.cancelQueries é chamado automaticamente

export function usePatchRequest(
  pathParamsArr,
  successCallback,
  errorCallback,
  startCallback,
  queryFunction
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (requestData) => {
      if (!queryFunction) {
        queryFunction = buildPatchRequest(
          [...pathParamsArr, requestData.id],
          requestData
        );
      }
      return queryFunction;
    },
    onMutate: async (newData) => {
      const queryKey = [...pathParamsArr, newData.id];
      const oldData = queryClient.getQueryData(queryKey);
      if (startCallback) {
        await queryClient.cancelQueries({queryKey: queryKey});
        startCallback(queryClient, oldData, newData);
      }
      return {oldData};
    },
    onSuccess: (newData, variables, context) => {
      const queryKey = [...pathParamsArr, newData.id];
      if (successCallback)
        successCallback(queryClient, context.oldData, newData);
      else {
        queryClient.setQueryData(queryKey, newData);
      }
    },
    onError: (error, variables, context) => {
      if (errorCallback)
        errorCallback(error, queryClient, context.oldData, variables);
      else {
        handleSubmitError(error, null);
      }
    },
  });
}

//successCallback, errorCallback, startCallback são OPCIONAIS

// Se não forem passadas, o comportamento padrão será executado
// Se forem passadas, o comportamento padrão NÃO SERÁ EXECUTADO

// A assinatura das funcoes e o comportamento padrão é:

// 1. successCallback:
// (queryClient, oldData, newData) => {}
//  Comportamento padrão: Atualiza o react-query do elemento com o novo dado baseado no pathParamArr fornecido
// 2. errorCallback:
// (error, queryClient, oldData, newData) => {}
//  Comportamento padrão: Notistack de erro

// 3. startCallback:
// (queryClient, oldData, newData) => {}
// Comportamento padrão: Nada
// Observação: Se o startCallback é fornecido, o queryClient.cancelQueries é chamado automaticamente

export function useDeleteRequest(
  pathParamsArr,
  successCallback,
  errorCallback,
  startCallback,
  queryFunction
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (requestData) => {
      if (!queryFunction) {
        queryFunction = buildDeleteRequest(
          [...pathParamsArr, requestData.id],
          requestData
        );
      }
      return queryFunction;
    },
    onMutate: async (newData) => {
      const queryKey = [...pathParamsArr, newData.id];
      const oldData = queryClient.getQueryData(queryKey);
      if (startCallback) {
        await queryClient.cancelQueries({queryKey: queryKey});
        startCallback(queryClient, oldData, newData);
      }
      return {oldData};
    },
    onSuccess: (newData, variables, context) => {
      const queryKey = [...pathParamsArr, newData.id];
      if (successCallback)
        successCallback(queryClient, context.oldData, newData);
      else {
        queryClient.removeQueries({queryKey: queryKey});
      }
    },
    onError: (error, variables, context) => {
      if (errorCallback)
        errorCallback(error, queryClient, context.oldData, variables);
      else {
        handleSubmitError(error, null);
      }
    },
  });
}

let batcherStorage = {};

export function buildBatchedGetRequest(pathParamsArr, queryParams) {
  const batcherStorageKey = pathParamsArr.slice(0, -1).join('/');
  let batcher = batcherStorage[batcherStorageKey];
  if (batcherStorage[batcherStorageKey] == undefined) {
    batcherStorage[batcherStorageKey] = create({
      fetcher: async (ids) => {
        const request = buildGetRequest(
          pathParamsArr.slice(0, -1),
          {
            id: ids,
            ...queryParams,
          },
          null
        );

        const result = await request();
        return result.results;
      },
      resolver: keyResolver('id'),
      scheduler: windowedFiniteBatchScheduler({
        windowMs: 40,
        maxBatchSize: 200,
      }),
    });
    batcher = batcherStorage[batcherStorageKey];
  }

  const id = pathParamsArr.slice(-1);
  return async () => {
    return batcher.fetch(id);
  };
}

//Função que pode ser usada ao invés de buildBatchedGetRequest e buildGetRequest para casos que se quer fazer batch de requisições do tipo:
// GET /posts?author=1
// GET /posts?author=2
// GET /posts?author=3
// Para /posts?author=1,2,3
// queryParamsConstant é o nome do parâmetro dentro do "queryParams" que é constante para todas as requisições. No exemplo acima, seria "author"
// Todos os outros parametros do queryParams deverão ser constantes para utilizar essa função

// resultKeyResolver é o nome do campo que será utilizado para filtrar os resultados. Por default, é o mesmo que o queryParamsConstant.
// Mas, em alguns casos que a resposta da requisição foge do padrao, pode ser necessário utilizar um campo diferente para filtrar os resultados. Exemplo:
// GET /posts?author=1,2,3
// {
//   results: [
//     {id: 1, author_id: 1},
//     {id: 2, author_id: 1},
//     {id: 3, author_id: 3},
//   ]
// }
// Nesse caso, o queryParamsConstant seria "author" e o resultKeyResolver seria "author_id"

export function buildBatchedGetRequestForArray(
  pathParamsArr,
  queryParams,
  queryParamsConstant,
  resultKeyResolver = false
) {
  if (!resultKeyResolver) resultKeyResolver = queryParamsConstant;

  let queryParamsWithoutConstant = {...queryParams};
  delete queryParamsWithoutConstant[queryParamsConstant];

  const batcherStorageKey =
    pathParamsArr.join('/') +
    JSON.stringify(
      queryParamsWithoutConstant,
      Object.keys(queryParamsWithoutConstant).sort()
    );
  let batcher = batcherStorage[batcherStorageKey];
  if (batcherStorage[batcherStorageKey] == undefined) {
    batcherStorage[batcherStorageKey] = create({
      fetcher: async (queryParamsArr) => {
        const queryParams = queryParamsArr.reduce((acc, curr) => {
          let currQueryParams = acc;
          Object.entries(curr).forEach(([key, value]) => {
            if (acc[key] == undefined) {
              currQueryParams[key] = value;
            } else if (!Array.isArray(acc[key])) {
              currQueryParams[key] = [acc[key], value];
            } else {
              currQueryParams[key] = [...acc[key], value];
            }
          });
          return currQueryParams;
        }, {});
        const request = buildGetRequest(pathParamsArr, queryParams, null);

        const result = await request();
        return result.results;
      },
      resolver: (results, query) => {
        return results.filter((result) => {
          if (Array.isArray(result[resultKeyResolver]))
            return result[resultKeyResolver].includes(
              query[queryParamsConstant]
            );
          if (Array.isArray(query[queryParamsConstant]))
            return query[queryParamsConstant].includes(
              result[resultKeyResolver]
            );

          return result[resultKeyResolver] == query[queryParamsConstant];
        });
      },
      scheduler: windowedFiniteBatchScheduler({
        windowMs: 40,
        maxBatchSize: 200,
      }),
    });
    batcher = batcherStorage[batcherStorageKey];
  }

  return async () => {
    return batcher.fetch(queryParams);
  };
}

export function buildGetRequest(pathParamsArr, queryParamsArr, contextReq) {
  let params = {
    method: 'GET',
    withCredentials: true,
    url: '',
  };

  if (contextReq) {
    params.headers = {
      Cookie: getAuthCookie(contextReq, params),
    };
  }

  params.url = buildURL(baseUrl, pathParamsArr, queryParamsArr);

  return async function () {
    return axios(params).then((res) => {
      return res.data;
    });
  };
}

export function buildListRequest(pathParamsArr, queryParamsArr, contextReq) {
  let params = {
    method: 'GET',
    withCredentials: true,
    url: '',
  };

  if (contextReq) {
    params.headers = {
      Cookie: getAuthCookie(contextReq, params),
    };
  }

  params.url = buildURL(baseUrl, pathParamsArr, queryParamsArr);

  return async function () {
    return axios(params).then((res) => {
      return res.data;
    });
  };
}

export async function buildPostRequest(pathParamsArr, bodyDict) {
  let params = {
    method: 'POST',
    withCredentials: true,
    url: '',
    data: bodyDict,
  };

  params.url = buildURL(baseUrl, pathParamsArr, []);

  return axios(params).then((res) => res.data);
}

export async function buildPutRequest(pathParamsArr, bodyDict) {
  let params = {
    method: 'PUT',
    withCredentials: true,
    url: '',
    data: bodyDict,
  };

  params.url = buildURL(baseUrl, pathParamsArr, []);

  return axios(params).then((res) => res.data);
}

export async function buildPatchRequest(pathParamsArr, bodyDict) {
  let params = {
    method: 'PATCH',
    withCredentials: true,
    url: '',
    data: bodyDict,
  };

  params.url = buildURL(baseUrl, pathParamsArr, []);

  return axios(params).then((res) => res.data);
}

export async function buildDeleteRequest(pathParamsArr, bodyDict) {
  let params = {
    method: 'DELETE',
    withCredentials: true,
    url: '',
    data: bodyDict,
  };

  params.url = buildURL(baseUrl, pathParamsArr, []);

  return axios(params).then((res) => res.data);
}

function getAuthCookie(contextReq) {
  const cookie = new Cookies(contextReq, null);
  const sessionCookie = cookie.get('sessionid');

  return 'sessionid=' + sessionCookie + ';';
}

function buildURL(baseUrl, pathParamsArr, queryParamsArr) {
  let url = pathParamsArr.reduce((url, param) => {
    if (['string', 'number'].includes(typeof param)) {
      url += param + '/';
    }
    return url;
  }, baseUrl);

  Object.entries(queryParamsArr).forEach(([key, value], index) => {
    if (index == 0) url += '?';
    if (index != 0) url += '&';

    if (Array.isArray(value)) {
      if (value.length == 1) {
        url += key + '=' + value[0];
        return;
      } else if (value.length != 0) {
        url += key + '=' + value.join(',');
      }
    } else {
      url += key + '=' + value;
    }
  });

  return url;
}
