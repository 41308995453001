export const neutralColor = {
  0: '#FFFFFF',
  50: '#FAFBFF',
  75: '#F9FAFC',
  100: '#F4F6FA',
  200: '#EDEFF5',
  300: '#E6E8F0',
  400: '#D8DAE5',
  500: '#C1C4D6',
  600: '#8f95b2',
  700: '#696f8c',
  800: '#474d66',
  900: '#252836',
  950: '#1A1D26',
  1000: '#000000',
};

export const brandColors = {
  primary: {
    lighter: '#466af7',
    light: '#466af7',
    main: '#093fcf',
    dark: '#002486',
    darker: '#001354',
  },
  secondary: {
    lighter: '#466af7',
    light: '#466af7',
    main: '#093fcf',
    dark: '#002486',
    darker: '#001354',
  },
};

export const commonColors = {
  blueProper: {
    dark: '#0f3699',
    regular: '#083fcf',
    light: '#2b62f3',
  },
  blueLight: {
    dark: '#CDDBFF',
    regular: '#E4ECFF',
    light: '#F3F6FF',
  },
  greenHighlight: {
    dark: '#DCF2EA',
    regular: '#EEF8F4',
    light: '#F5FBF8',
  },
  greenProper: {
    dark: '#088a59',
    regular: '#17ae75',
    light: '#41d19b',
  },
  redHighlight: {
    dark: '#f4b6b6',
    regular: '#f9dada',
    light: '#fdf4f4',
  },
  redProper: {
    dark: '#a70f0f',
    regular: '#da2424',
    light: '#f05252',
  },
  yellowHighlight: {
    dark: '#FFDFA6',
    regular: '#FFEFD2',
    light: '#FFFAF1',
  },
  yellowProper: {
    dark: '#ED9419',
    regular: '#F6B941',
    light: '#F8C74E',
  },
  orangeHighlight: {
    dark: '#F2D5C4',
    regular: '#F9E5DA',
    light: '#FDF1EA',
  },
  orangeProper: {
    dark: '#EC6226',
    regular: '#F67741',
    light: '#F78758',
  },
  purpleHighlight: {
    dark: '#E3CCFC',
    regular: '#F1E4FF',
    light: '#F8F2FF',
  },
  purpleProper: {
    dark: '#5B02B4',
    regular: '#6C08CF',
    light: '#801DE3',
  },
};

export const errorColor = {
  main: commonColors.redProper.regular,
  light: commonColors.redProper.light,
  dark: commonColors.redProper.dark,
};

export const progressColors = {
  0: '#F62E44',
  126: '#F65046',
  251: '#F77649',
  376: '#FB8D33',
  501: '#FFA41C',
  626: '#C4B11F',
  751: '#84BF23',
  876: '#4EAE37',
  1000: '#169C4C',
};

export const colorTokens = {
  border: {
    default: neutralColor[400],
    light: neutralColor[200],
  },
  white: neutralColor[0],
  black: neutralColor[1000],
  icon: neutralColor[800],
  brandPrimary: brandColors.primary.main,
  brandPrimaryHighlight: neutralColor[200],
  body: {
    background: neutralColor[100],
    containerDivLight: neutralColor[200],
    containerDivDark: neutralColor[300],
    placeholder: neutralColor[400],
  },
  button: {
    primary: {
      background: brandColors.primary.main,
      text: neutralColor[0],
      hoverModifier: 'rgba(255, 255, 255, 0.12)',
      focusedModifier: 'rgba(255, 255, 255, 0.12)',
      pressedModifier: 'rgba(0, 0, 0, 0.12)',
      disabledBackground: neutralColor[400],
      disabledText: neutralColor[600],
    },
    secondary: {
      background: neutralColor[0],
      border: neutralColor[400],
      text: brandColors.primary.main,
    },
  },
  sidebar: {
    menuBackground: brandColors.primary.dark,
    menuActiveItemBackground: brandColors.primary.darker,
    menuIconInactive: neutralColor[0],
    menuIconActive: neutralColor[0],
  },
  textInput: {
    background: neutralColor[0],
    border: neutralColor[400],
    textFilled: neutralColor[1000],
    textPlaceholder: neutralColor[600],
  },
  sidePanel: {
    background: neutralColor[200],
  },
  text: {
    primary: neutralColor[0],
    secondary: neutralColor[0],
  },
};
